import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
Swiper.use([Navigation, Pagination]);
const swiper = new Swiper('.slider__swiper', {
  slidesPerView: 'auto',
  spaceBetween: '100',
  centeredSlides: true,
  loop: true,
  pagination: {
    el: ".swiper-pagination",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    567: {
      slidesPerView: 'auto',
    },
    320: {
      slidesPerView: 1,
    },
  },
});
