import GraphModal from 'graph-modal';
const modal = new GraphModal();

document.querySelectorAll('[data-graph-path="reviews"]').forEach((el) => {
  el.addEventListener('click', () => {
    const iframe = document?.getElementById('iframe')
    const path = el.getAttribute('data-you-path');
    const title = el.getAttribute('data-title');
    iframe.src = path;
    iframe.title = title;
  })
})

