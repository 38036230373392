import { validateForms } from '../functions/validate-forms.js';

const optFormFrst = [
  {
    ruleSelector: '.request__form-input--name',
    rules: [
      {
        rule: 'minLength',
        value: 2,
        errorMessage: 'Минимум 2 буквы!'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!',
      }
    ]
  },
  {
    ruleSelector: '.request__form-input--phone',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const optFormScnd = [
  {
    ruleSelector: '.request__form-input--name',
    rules: [
      {
        rule: 'minLength',
        value: 2,
        errorMessage: 'Минимум 2 буквы!'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!',
      }
    ]
  },
  {
    ruleSelector: '.request__form-input--phone',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm = () => {
  document.querySelector('.grah-modal').classList.add('is-open');
  document.querySelector('.success-modal__container').classList.add('graph-modal-open fade animate-open');
  setTimeout(()=>{
    document.querySelector('.success__modal-close').click();
  },2000);
};

validateForms('.request__form--frst', optFormFrst, afterForm);
validateForms('.request__form--scnd', optFormScnd, afterForm);
